.arts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 165px;
}
.arts .content {
  display: flex;
  align-items: center;
  margin-bottom: 165px;
  width: 100%;
}
.arts .content .left {
  height: 100%;
  margin-right: 45px;
  border-radius: 50%;
  margin-bottom: auto;
  margin-top: 218px;
}
.arts .content .left:hover {
  cursor: pointer;
}
.arts .content .right {
  height: 100%;
  margin-left: 45px;
  border-radius: 50%;
  margin-bottom: auto;
  margin-top: 218px;
}
.arts .content .right:hover {
  cursor: pointer;
}
.arts .content .images::-webkit-scrollbar {
  display: none;
}
.arts .content .images {
  display: grid;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  grid-row-gap: 75px;
  grid-column-gap: 45px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1350px) {
  .arts .content .left {
    width: 45px;
    height: 45px;
    margin-right: 40px;
    margin-top: 226.5px;
  }
  .arts .content .right {
    width: 45px;
    height: 45px;
    margin-left: 40px;
    margin-top: 226.5px;
  }
  .arts {
    margin-bottom: 100px;
  }
  .arts .content {
    margin-bottom: 100px;
  }
  .arts .textSubtitle{
    margin-top: 30px;
  }
}

@media (max-width: 1115px) {
  .arts .content .left {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    margin-top: 228px;
  }
  .arts .content .right {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-top: 228px;
  }
  .arts {
    margin-bottom: 75px;
  }
  .arts .content {
    margin-bottom: 75px;
  }
  .arts .content .images {
    grid-row-gap: 40px;
    grid-column-gap: 8px;
  }
}
@media (max-width: 750px) {
  .arts .content .images {
    grid-row-gap: 40px;
    grid-column-gap: 2px;
  }
}

@media (max-width: 650px) {
  .arts{
    margin-bottom: 15px;
  }
}

@media (max-width: 550px) {
  .arts .content .left {
    display: none;
  }
  .arts .content .right {
    display: none;
  }
  .arts .content .images {
    grid-row-gap: 40px;
    grid-column-gap: 8px;
  }
  .arts .textSubtitle{
    margin-bottom: 70px;
  }
}

@media (max-width: 420px) {
  .arts .desktopLandingTitle {
    right: -25px !important;
    width: 200px !important;
  }
  .arts .content .images {
    grid-row-gap: 40px;
    grid-column-gap: 8px;
  }
}

@media (max-width: 395px) {
  .arts .desktopLandingTitle {
    right: -15px !important;
  }
}

@media (max-width: 385px) {
  .artists .content {
    grid-row-gap: 15px;
    grid-column-gap: 5px;
  }
  .arts .headTitle {
    word-spacing: 9999999px;
  }
  .arts .desktopLandingTitle {
    right: 35px !important;
    top: auto !important;
    bottom: -14px !important;
  }
}
@media (max-width: 365px) {
  .arts .content .images {
    grid-column-gap: 5px;
  }
}
