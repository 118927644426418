.art {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 500ms;
}

.artImg {
  max-width: 278px !important;
  height: 496px;
  margin-bottom: 17px;
}

.artImg:hover {
  cursor: pointer;
}

.art .name {
  font-size: 30px;
  width: 50%;
  line-height: 35px;
  font-family: "BloggerSans-Bold";
  color: #4f2c1d;
  margin-bottom: 12px;
  font-weight: bold;
}

.shortName {
  font-size: 30px;
  line-height: 35px;
  width: 30%;
  font-family: "BloggerSans-Bold";
  color: #4f2c1d;
  margin-bottom: 12px;
  font-weight: bold;
}

.art .info {
  font-size: 25px;
  line-height: 27px;
  font-family: "GothamBook";
  color: #4f2c1d;
  margin-bottom: 27px;
}

.art .btn {
  width: 220px;
  height: 50px;
  background: #93272c;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-family: "BloggerSans-Bold";
  color: #fff6d8;
  margin-bottom: 0;
  margin-top: auto;
}

.art .btn:hover {
  cursor: pointer;
}
@media (max-width: 1920px) {
  .art .name {
    font-size: 39px;
    width: 50%;
    line-height: 47px;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    margin-bottom: 12px;
    font-weight: bold;
  }
  .shortName {
    font-size: 39px;
    line-height: 47px;
    width: 20%;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    margin-bottom: 12px;
    font-weight: bold;
  }
  .art .info {
    font-size: 30px;
    line-height: 32px;
    font-family: "GothamBook";
    color: #4f2c1d;
    margin-bottom: 27px;
  }
  .art .btn {
    width: 280px;
    height: 60px;
    background: #93272c;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    line-height: 24px;
    font-family: "BloggerSans-Bold";
    color: #fff6d8;
    margin-bottom: 0;
    margin-top: auto;
  }
}
@media (max-width: 1400px) {
  .art .name {
    font-size: 35px;
    line-height: 41px;
    width: 60%;
  }

  .shortName {
    font-size: 35px;
    line-height: 41px;
    width: 30%;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    font-weight: bold;
  }

  .art .info {
    font-size: 28px;
    line-height: 29px;
  }

  .art .btn {
    width: 230px;
    height: 50px;
    font-size: 22px;
    line-height: 21px;
  }
  .artImg {
    width: 350px;
    height: auto;
    margin-bottom: 17px;
  }
}

@media (max-width: 1200px) {
  .art .name {
    font-size: 33px;
    line-height: 39px;
    width: 60%;
  }

  .shortName {
    font-size: 33px;
    line-height: 39px;
    width: 30%;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    font-weight: bold;
  }

  .art .info {
    font-size: 26px;
    line-height: 26px;
  }

  .art .btn {
    width: 210px;
    height: 45px;
    font-size: 20px;
    line-height: 21px;
  }
  .artImg {
    width: 300px;
    height: auto;
    margin-bottom: 17px;
  }
}
@media (max-width: 1050px) {
  .art .name {
    font-size: 29px;
    width: 60%;
  }
  .shortName {
    font-size: 29px;
  }
  .art .btn {
    width: 180px;
    height: 40px;
    font-size: 18px;
    line-height: 21px;
  }
  .artImg {
    width: 250px;
    height: auto;
    margin-bottom: 17px;
  }
}

@media (max-width: 900px) {
  .art .name {
    font-size: 24px;
    line-height: 30px;
    width: 60%;
  }

  .shortName {
    font-size: 24px;
    line-height: 30px;
    width: 30%;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    font-weight: bold;
  }
  .art .info {
    font-size: 20px;
    line-height: 23px;
  }
  .artImg {
    width: 200px;
    height: auto;
    margin-bottom: 17px;
  }
}

@media (max-width: 750px) {
  .art .name {
    font-size: 20px;
    line-height: 21px;
    width: 60%;
  }
  .shortName {
    font-size: 20px;
    line-height: 21px;
    width: 30%;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    font-weight: bold;
  }
  .art .btn {
    width: 140px;
    height: 35px;
    font-size: 18px;
    line-height: 21px;
  }
  .art .info {
    font-size: 15px;
    line-height: 16px;
  }
  .artImg {
    width: 150px;
    height: auto;
    margin-bottom: 17px;
  }
}

@media (max-width: 600px) {
  .art .name {
    font-size: 16px;
    line-height: 27px;
    width: 70%;
  }

  .shortName {
    font-size: 16px;
    line-height: 27px;
    width: 30%;
    font-family: "BloggerSans-Bold";
    color: #4f2c1d;
    font-weight: bold;
  }

  .art .info {
    font-size: 14px;
    line-height: 19px;
  }

  .art .btn {
    width: 120px;
    height: 30px;
  }
  .artImg {
    width: 110px;
    height: auto;
    margin-bottom: 17px;
  }
}
@media (max-width: 530px) {
  .art .name {
    line-height: 17px;
  }
  .shortName {
    line-height: 17px;
  }
  .art .info {
    font-size: 10px;
    line-height: 11px;
  }
  .artImg {
    width: 121px;
    height: auto;
    margin-bottom: 17px;
  }
}
@media (max-width: 460px) {
  .artImg {
    width: 110px;
    height: auto;
    margin-bottom: 17px;
  }
}
@media (max-width: 420px) {
  .art {
    margin-right: 0px;
  }
  .art .btn {
    width: 115px;
    height: 30px;
  }
  .art .name {
    font-size: 16px;
    width: 80%;
  }
  .shortName {
    font-size: 16px;
  }
  .artImg {
    width: 110px;
    height: auto;
    margin-bottom: 17px;
  }
}
@media (max-width: 380px) {
  .art .btn {
    width: 100px;
    height: 30px;
  }
}
@media (max-width: 360px) {
  .art .btn {
    width: 100px;
    height: 30px;
  }
}
