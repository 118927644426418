.artists{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}
.artists .content{
  display: grid;
  grid-row-gap: 75px;
  grid-column-gap: 45px;
  grid-template-columns: repeat(3, 1fr);
}
.artists .textSubtitle{
  margin-bottom: 85px;
}

@media (max-width: 1400px) {
  .artists .content{
    grid-row-gap: 55px;
    grid-column-gap: 35px;
  }
}

@media (max-width: 1250px) {
  .artists .content{
    grid-row-gap: 45px;
    grid-column-gap: 25px;
  }
}

@media (max-width: 900px) {
  .artists .content{
    grid-row-gap: 25px;
    grid-column-gap: 15px;
  }
}

@media (max-width: 480px) {
  .artists .content{
    grid-row-gap: 20px;
    grid-column-gap: 8px;
  }
  .artists .headTitle{
    word-spacing: 9999999px;
  }
  .artists .desktopLandingTitle{
    top: auto !important;
    bottom: -20px !important;
    right: 60px !important;
    width: 200px !important;
  }
}

@media (max-width: 385px) {
  .artists .content{
    grid-row-gap: 15px;
    grid-column-gap: 5px;
  }
}

@media (max-width: 360px) {
  .artists .desktopLandingTitle{
    right: 40px !important;
  }
}
