.gallery3D {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery3D .textSubtitle {
  margin-top: 85px;
}

.gallery3D .iframe {
  width: 75%;
  height: 100%;
  min-height: 600px;
}

@media (max-width: 975px) {
  .gallery3D .textSubtitle {
    margin-top: 40px !important;
  }
}

@media (max-width: 750px) {
  .gallery3D .iframe {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .gallery3D .headTitle {
    word-spacing: 9999999px;
  }

  .gallery3D .desktopLandingTitle {
    right: 73px !important;
    top: 60px !important;
    max-width: 200px;
  }
}

@media (max-width: 425px) {
  .gallery3D .iframe {
    min-height: 400px;
  }
}