.gallery2D{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
.gallery2D .title2{
    color: #93272c;
    font-size: 26px;
    line-height: 26px;
    font-family: "BloggerSans-Bold";
    margin-left: 78px;
    margin-right: auto;
    margin-bottom: 25px;
}
.gallery2D .textSubtitle{
  margin-bottom: 50px;
  margin-top: 40px;
}
.gallery2D .imageSlider{
  height: 495px;
  width: 100%;
  margin-bottom: 85px;
}
.gallery2D .imageSlider .content{
  display: flex;
  align-items: center;
  margin-bottom: 65px;
  width: 100%;
}
.gallery2D .imageSlider .content .images{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.gallery2D .imageSlider .content .images img{
  max-width: 750px;
  max-height: 394px;
  width: calc(50% - 16px);
  z-index: 30;
}
.gallery2D .imageSlider .content .images img:first-child{
  margin-right: 32px;
}
.gallery2D .imageSlider .content .left{
  height: 100%;
  margin-right: 53px;
  border-radius: 50%;
}
.gallery2D .imageSlider .content .left:hover{
  cursor: pointer;
}
.gallery2D .imageSlider .content .right{
  height: 100%;
  margin-left: 53px;
  border-radius: 50%;
}
.gallery2D .imageSlider .content .right:hover{
  cursor: pointer;
}
.gallery2D .imageSlider .bullets{
  display: flex;
  justify-content: center;
}
.gallery2D .imageSlider .bullets .bullet{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
  background: #B58500;
}
.gallery2D .imageSlider .bullets .emptyBullet{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 20px;
  border: 4px solid #B58500;
}
.gallery2D .imageSlider .bullets .emptyBullet:hover{
  cursor: pointer;
}
.gallery2D .imageSlider .bullets .emptyBullet:last-child{
  margin-right: 0;
}
.gallery2D .imageSlider .bullets .bullet:last-child{
  margin-right: 0;
}
.gallery2D .imageSlider .displayMobile{
  display: none;
}
.gallery2D .imageSlider .displayDesktop{
  display: flex;
  flex-direction: row;
}

@media (max-width: 1350px) {
  .gallery2D .imageSlider{
    height: max-content;

    margin-bottom: 85px;
  }
  .gallery2D .imageSlider .content .left{
    width: 45px;
    height: 45px;
    margin-right: 40px;
    border-radius: 50%;
  }
  .gallery2D .imageSlider .content .right{
    width: 45px;
    height: 45px;
    margin-left: 40px;
    border-radius: 50%;
  }
}

@media (max-width: 1115px) {
  .gallery2D .imageSlider .content .left{
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 50%;
  }
  .gallery2D .imageSlider .content .right{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    border-radius: 50%;
  }
}

@media (max-width: 975px) {
  .gallery2D .imageSlider .content .images{
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .gallery2D .imageSlider .content .images::-webkit-scrollbar {
    display: none;
  }
  .gallery2D .imageSlider .content .images img{
    width: 366px;
    height: 244px;
      margin-right: 20px;
  }
  .gallery2D .imageSlider .displayMobile{
    display: flex;
    flex-direction: row;
  }
  .gallery2D .imageSlider .displayDesktop{
    display: none;
  }
  .gallery2D .title2{
    margin-left: 0;
  }
}

@media (max-width: 650px) {
  .gallery2D .imageSlider{
    margin-bottom: 35px;
  }
  .gallery2D .imageSlider .content .left{
    display: none;
  }
  .gallery2D .imageSlider .content .right{
    display: none;
 }
 .gallery2D .imageSlider .content .images img{
   width: 263px;
   height: 176px;
 }
}

@media (max-width: 340px) {
  .gallery2D .desktopLandingTitle{
    right: -15px !important;
  }
}
