.landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  
  position: relative;
  overflow-x: hidden;
  padding-left: 240px;
  padding-right: 240px;
  overflow: hidden;
}

.landing .bck {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -3;
}

.headTitle {
  font-size: 65px;
  line-height: 77px;
  color: #93272c;
  font-family: "BloggerSans-Bold";
  position: relative;
  width: max-content;
  margin-bottom: 100px;
}

.headTitle .desktopLandingTitle {
  position: absolute;
  right: -37px;
  top: 15px;
  max-width: 100%;
}

.textSubtitle {
  font-size: 25px;
  line-height: 27px;
  font-family: "GothamBook";
  color: #4f2c1d;
  text-align: left;
  padding-left: 78px;
  padding-right: 78px;
  margin-bottom: 130px;
}

.landing .brush1 {
  position: absolute;
  top: 775px;
  right: -190px;
}

.landing .brush2 {
  position: absolute;
  left: -190px;
  top: 1820px;
}

.landing .brush3 {
  position: absolute;
  right: -360px;
  top: 4650px;
}

.landing .brush4 {
  position: absolute;
  left: -140px;
  top: 8620px;
}

@media (max-width: 1920px) and (min-width: 1550px) {
  .landing {
    /* padding-left: calc((100vw - 1440px) / 2);
    padding-right: calc((100vw - 1440px) / 2);  if they want the first versio with bigger padding*/
    padding-left: 55.5px;
    padding-right: 55.5px;
  }

  .landing .brush1 {
    position: absolute;
    top: 1015px;
    right: -190px;
  }

  .landing .brush3 {
    position: absolute;
    right: -390px;
    top: 4750px;
  }

  .landing .brush2 {
    position: absolute;
    top: 1960px;
  }

  .landing .brush4 {
    position: absolute;
    left: -190px;
    top: 8900px;
  }
}

@media (max-width: 1850px) {
  .landing .brush1 {
    top: 955px;
  }
}

@media (max-width: 1800px) {
  .landing .brush1 {
    right: -230px;
    top: 920px;
  }

  @media (max-width: 1750px) {
    .landing .brush1 {
      right: -230px;
      top: 870px;
    }

    .landing .brush2 {
      top: 1870px;
    }
    .landing .brush3 {
      top: 4670px !important;
      right: -400px;
    }
  }

  @media (max-width: 1620px) {
    .landing .brush1 {
      top: 840px;
    }
  }

  @media (max-width: 1600px) {
    .landing .brush1 {
      right: -230px;
      top: 800px;
    }

    .landing .brush2 {
      top: 1820px;
      left: -230px;
    }
  }


  .landing .brush4 {
    left: -210px;
    top: 8900px;
  }
}

@media (max-width: 1630px) {
  .landing .brush4 {
    left: -210px;
    top: 8750px;
  }
}

@media (max-width: 1550px) {
  .landing {
    padding-left: 55.5px;
    padding-right: 55.5px;
  }

  .landing .headTitle {
    font-size: 55px;
    line-height: 68px;
    width: max-content;
    margin-bottom: 100px;
  }

  .landing .textSubtitle {
    font-size: 21px;
    line-height: 24px;
  }

  .landing .brush1 {
    top: 705px;
  }

  .landing .brush2 {
    top: 1670px;
  }

  .landing .brush3 {
    top: 4480px !important;
    right: -450px;
  }

  .landing .brush4 {
    top: 8550px;
  }
}

@media (max-width: 1400px) {
  .landing .brush3 {
    top: 4125px !important;
  }

  .landing .brush4 {
    top: 8150px;
    left: -270px;
  }
}

@media (max-width: 1350px) {
  .landing .headTitle {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 80px;
  }

  .landing .textSubtitle {
    font-size: 20px;
    line-height: 22px;
  }

  .landing .brush1 {
    right: -360px;
    top: 600px;
  }

  .landing .brush2 {
    left: -410px;
    top: 1430px;
  }

  .landing .brush3 {
    top: 3910px !important;
    right: -470px;
  }

  .landing .brush4 {
    left: -340px;
    top: 7860px;
  }
}

@media (max-width: 1290px) {
  .landing .brush3 {
   display: none;
  }
}

@media (max-width: 1250px) {
  .landing .brush1 {
    top: 530px;
  }
  .landing .brush4 {
    display: none;
  }
}

@media (max-width: 1115px) {
  .landing {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1050px) {
  .landing .brush1 {
    display: none;
  }

  .landing .brush2 {
    display: none;
  }
}

@media (max-width: 975px) {
  .landing .headTitle {
    font-size: 42px;
    line-height: 56px;
    margin-bottom: 70px;
  }

  .landing .textSubtitle {
    font-size: 18px;
    line-height: 20px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0 !important;
  }

  .landing .brush1 {
    display: none;
  }

  .landing .brush2 {
    display: none;
  }

  .landing .brush3 {
    display: none;
  }

  .landing .brush4 {
    display: none;
  }
}

@media (max-width: 550px) {
  .landing {
    padding-left: 30px;
    padding-right: 30px;
  }

  .landing .headTitle {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 65px;
    width: auto;
  }

  .landing .textSubtitle {
    font-size: 17px;
    line-height: 18px;
  }
}

@media (max-width: 425px) {
  .landing {
    padding-left: 10px;
    padding-right: 10px;
  }
}