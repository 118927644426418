@font-face {
  font-family: 'GothamBook';
  src: url('./fonts/GothamBook.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'BloggerSans-Bold';
  src: url('./fonts/BloggerSans-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'BloggerSans-Regular';
  src: url('./fonts/BloggerSans.woff') format('woff');
}

.App {
  text-align: center;
  max-width: 1920px;
  background-image: url("./images/wallBackground.png");
  background-size: auto;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}