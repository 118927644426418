.mainPopup{
  background: rgb(171, 35, 40, 0.9);
  width: 100%;
  min-height: 900px;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
}
.mainPopup .content{
  width: 862px;
  height: 691px;
  background: #FFF6D8;
  color: #4F2C1D;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  line-height: 47px;
  font-weight: bold;
  font-family: 'BloggerSans-Bold';
  position: relative;
  padding-left: 150px;
  padding-right: 150px;
  text-align: center;
}
.mainPopup  .close{
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  z-index: 15555;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainPopup  .close:hover{
  cursor: pointer;
}
.mainPopup  .close img{
  pointer-events: none;
}

@media (max-width: 1300px) {
  .mainPopup .content{
    width: 645px;
    height: 520px;
    font-size: 35px;
    line-height: 42px;
    padding-left: 120px;
    padding-right: 120px;
  }
  .mainPopup  .close{
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 975px) {
  .mainPopup .content{
    width: 535px;
    height: 450px;
    font-size: 30px;
    line-height: 36px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .mainPopup  .close{
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 800px) {
  .mainPopup .content{
    width: 450px;
    height: 400px;
    font-size: 25px;
    line-height: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 600px) {
  .mainPopup .content{
    width: 60%;
    height: 450px;
  }
  .mainPopup  .close{
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 450px) {
  .mainPopup .content{
    width: 60%;
    height: max-content;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .mainPopup  .close{
    width: 35px;
    height: 35px;
  }
}
