.welcome {
  width: 100%;
}

/*This is for banner 2024 TEXT*/
.welcome .container .text {
  padding-top: 0 !important;
}

.welcome .banner2024 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome .banner2024 .title {
  font-size: 65px !important;
  line-height: 1 !important;
  max-width: 100% !important;
}

@media (max-width: 1550px) {
  .welcome .container .text {
    max-width: 70% !important;
    width: 70% !important;
  }
}

@media (max-width: 1550px) {
  .welcome .banner2024 .title {
    font-size: 53px !important;
  }
}

@media (max-width: 1350px) {
  .welcome .banner2024 .title {
    font-size: 48px !important;
  }
}

@media (max-width: 1230px) {
  .welcome .banner2024 .title {
    font-size: 44px !important;
  }
}

@media (max-width: 992px) {
  .welcome .container .text {
    padding-top: 25px !important;
    max-width: 95% !important;
    width: 95% !important;
  }
}

@media (max-width: 500px) {
  .welcome .container .text {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .welcome .banner2024 .title {
    font-size: 31px !important;
  }

  .welcome .banner2024 .subtitle {
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-size: 18px !important;
  }
}

@media (max-width: 360px) {
  .welcome .banner2024 .title {
    font-size: 28px !important;
  }
}

.welcome .container {
  background: #93272c;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 66px;
}

.welcome .container .desktopWelcomeFlag {
  position: absolute;
  right: -55px;
  top: 0;
}

.welcome .container .desktopWelcomeRestrict {
  position: absolute;
  right: 13px;
  bottom: 13px;
}

.welcome .container .desktopWelcomeLogo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -93px;

}

.welcome .container .desktopWelcomeCan {
  position: absolute;
  right: 105px;
  bottom: -130px;
}

.welcome .container .desktopWelcomeIcons {
  position: absolute;
  right: 0;
  bottom: -48px;
}

.welcome .container .text {
  padding-left: 46px;
  padding-top: 58px;
  color: #fff6d8;
  text-align: left;
  width: 50%;
  box-sizing: border-box;
}

.welcome .container .text .title {
  font-size: 70px;
  font-family: "BloggerSans-Bold";
  max-width: 514px;
  margin-bottom: 26px;
  line-height: 93px;
}

.welcome .container .text .subtitle {
  font-size: 30px;
  font-family: "BloggerSans-Regular";
  max-width: 514px;
  margin-bottom: 33px;
  line-height: 32px;
}

.welcome .container .text .info {
  font-size: 25px;
  font-family: "GothamBook";
  max-width: 641px;
  line-height: 27px;
  margin-bottom: 40px;
}

.welcome .container .text .whiteBrushContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 113px;
  padding-left: 48px;
  padding-right: 76px;
  max-width: 500px;
  box-sizing: border-box;
}

.welcome .container .text .desktopWelcomeWhiteBrush {
  position: absolute;
  max-width: 500px;
  left: 0;
}

.welcome .container .text .whiteBrushContainer .date {
  color: #93272c;
  font-size: 26px;
  line-height: 26px;
  font-family: "BloggerSans-Bold";
  z-index: 2;
  text-align: center;
}

.welcome .container .text img {
  width: 670px;
}

.welcome .container .desktopWelcomeBrush {
  max-width: 100%;
  max-height: 100%;
  width: 50%;
}

.welcome .btns {
  display: flex;
  margin-bottom: 155px;
}

.welcome .btns>a:hover {
  cursor: pointer;
}

.welcome .btns .btn {
  width: 300px;
  height: 70px;
  background: #93272c;
  color: #fff6d8;
  font-size: 28px;
  line-height: 33px;
  font-family: "BloggerSans-Bold";
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome .btns .btn:hover {
  cursor: pointer;
}

.welcome .btns .btn:first-child {
  margin-right: 30px;
}

.disableMobile {
  display: none;
}

.welcome .container .mobileWelcomeBrush {
  display: none;
}

.mobileInfo {
  display: none;
}

@media (max-width: 1550px) {
  .welcome .container .text .title {
    font-size: 59px;
    margin-bottom: 20px;
    line-height: 79px;
  }

  .welcome .container .text .subtitle {
    margin-bottom: 26px;
    font-size: 29px;
    line-height: 29px;
  }

  .welcome .container .text .info {
    font-size: 21px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .welcome .container .text .whiteBrushContainer {
    padding-left: 35px;
    padding-right: 60px;
    width: 450px;
  }

  .welcome .container .text .desktopWelcomeWhiteBrush {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 450px;
    height: 113px;
  }

  .welcome .container .text .whiteBrushContainer .date {
    font-size: 22px;
    line-height: 22px;
  }

  .welcome .container .desktopWelcomeBrush {
    max-width: 630px;
  }

  .welcome .container .desktopWelcomeCan {
    max-height: 660px;
    max-width: 450px;
    bottom: -110px;
  }

  .welcome .container .desktopWelcomeLogo {
    max-width: 165px;
    bottom: -84px;
  }

  .welcome .btns {
    margin-bottom: 125px;
  }

  .welcome .btns .btn {
    width: 250px;
    height: 58px;
    font-size: 24px;
    line-height: 28px;
  }

  .welcome .btns .btn:first-child {
    margin-right: 20px;
  }
}

@media (max-width: 1350px) {
  .welcome .container .text .title {
    font-size: 50px;
    line-height: 66px;
  }

  .welcome .container .text .subtitle {
    margin-bottom: 18px;
    font-size: 27px;
    line-height: 27px;
  }

  .welcome .container .text .info {
    font-size: 21px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .welcome .container .desktopWelcomeCan {
    max-height: 560px;
    max-width: 400px;
    bottom: -92px;
  }

  .welcome .container .desktopWelcomeFlag {
    right: -45px;
    width: 90px;
    height: 130px;
  }

  .welcome .container .desktopWelcomeRestrict {
    width: 88px;
    height: 81px;
  }

  .welcome .container .desktopWelcomeLogo {
    max-width: 150px;
    bottom: -76px;
  }

  .welcome .container .text .whiteBrushContainer {
    padding-left: 30px;
    padding-right: 48px;
    width: 490px;
  }

  .welcome .container .text .desktopWelcomeWhiteBrush {
    position: absolute;
    top: 0;
    left: 0;
    width: 580px;
    height: 113px;
  }

  .welcome .btns {
    margin-bottom: 110px;
  }

  .welcome .btns .btn {
    width: 230px;
    height: 52px;
    font-size: 22px;
    line-height: 24px;
  }

  .welcome .btns .btn:first-child {
    margin-right: 17px;
  }
}

@media (max-width: 1230px) {
  .welcome .container .text {
    padding-left: 25px;
    padding-top: 35px;
  }

  .welcome .container .text .title {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 10px;
  }

  .welcome .container .text .subtitle {
    font-size: 25px;
    line-height: 25px;
  }

  .welcome .container .text .info {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 25px;
  }

  .welcome .container .text .whiteBrushContainer .date {
    font-size: 20px;
    line-height: 20px;
  }

  .welcome .container .text .whiteBrushContainer {
    padding-left: 35px;
    width: 440px;
  }

  .welcome .container .text .desktopWelcomeWhiteBrush {
    width: 530px;
  }

  .welcome .btns {
    margin-bottom: 100px;
  }

  .welcome .btns .btn {
    width: 215px;
    height: 48px;
    font-size: 20px;
    line-height: 21px;
  }

  .welcome .btns .btn:first-child {
    margin-right: 15px;
  }
}

@media (max-width: 1115px) {
  .welcome .container .text {
    padding-left: 15px;
    padding-top: 25px;
  }

  .welcome .container .desktopWelcomeFlag {
    right: -35px;
    width: 70px;
    height: 100px;
  }

  .welcome .container .text .whiteBrushContainer {
    height: 80px;
    max-width: 400px;
  }

  .welcome .container .text .desktopWelcomeWhiteBrush {
    height: 80px;
    max-width: 400px;
  }

  .welcome .container .desktopWelcomeLogo {
    max-width: 130px;
    bottom: -66px;
  }

  .welcome .container .desktopWelcomeCan {
    max-height: 470px;
    max-width: 350px;
    bottom: -79px;
  }

  .welcome .container .desktopWelcomeRestrict {
    width: 75px;
    height: 67px;
  }

  .welcome .container .desktopWelcomeIcons {
    width: 77px;
    height: 25px;
    bottom: -35px;
  }

  .welcome .container {
    margin-bottom: 85px;
  }
}

@media (max-width: 992px) {
  .welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .welcome .container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    padding-bottom: 370px;
    margin-bottom: 100px;
  }

  .welcome .container .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding-left: 0;
    text-align: center;
  }

  .welcome .container .text .title {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .welcome .container .text .subtitle {
    max-width: 100%;
    margin-bottom: 25px;
    z-index: 30;
  }

  .disableDesktop {
    display: none;
  }

  .welcome .container .text .whiteBrushContainer {
    flex-direction: column;
    position: static;
    height: max-content;
    /* padding-left: 0; */
  }

  .welcome .container .text .whiteBrushContainer .date {
    background-image: url("../../../images/desktopWelcomeWhiteBrush.png");
    background-repeat: no-repeat;
    background-size: 270px 87px;
    width: 270px;
    min-height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .disableMobile {
    display: flex;
  }

  .welcome .container .desktopWelcomeBrush {
    display: none;
  }

  .welcome .container .desktopWelcomeCan {
    max-height: 370px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 35px;
  }

  .welcome .container .mobileWelcomeBrush {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 450px;
    height: 430px;
    display: block;
  }

  .welcome .container .desktopWelcomeFlag {
    position: absolute;
    right: 5%;
    top: auto;
    bottom: 250px;
    display: none;
  }

  .welcome .container .desktopWelcomeIcons {
    left: 0;
    bottom: -55px;
    left: 15px;
  }

  .welcome .container .desktopWelcomeRestrict {
    bottom: -75px;
    right: 15px;
  }

  .mobileInfo {
    margin-bottom: 65px;
    font-size: 22px;
    line-height: 22px;
    font-family: "GothamBook";
    color: #fff6d8;
    padding-top: 10px;
    text-align: center;
    display: block;
  }
}

@media (max-width: 550px) {
  .welcome .container .text {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 28px;
    width: 100%;
  }

  .welcome .container .text .title {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 25px;
  }

  .welcome .container .text .subtitle {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .welcome .container .text .whiteBrushContainer {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome .container .text .whiteBrushContainer .date {
    font-size: 20px;
    line-height: 24px;
  }

  .welcome .container .desktopWelcomeCan {
    max-height: 295px;
    width: 192px;
    bottom: 35px;
  }

  .welcome .container .mobileWelcomeBrush {
    max-width: 353px;
    max-height: 418px;
  }

  .welcome .container .desktopWelcomeFlag {
    width: 62px;
    height: 90px;
    bottom: 250px;
  }

  .welcome .container .desktopWelcomeLogo {
    max-width: 125px;
    bottom: -63px;
  }

  .welcome .container .desktopWelcomeIcons {
    width: 73px;
    height: 23px;
  }

  .welcome .container .desktopWelcomeRestrict {
    width: 55px;
    height: 50px;
    bottom: -65px;
  }

  .mobileInfo {
    margin-bottom: 55px;
    font-size: 17px;
    line-height: 18px;
    color: #fff6d8;
    padding-top: 10px;
    text-align: center;
  }

  .welcome .btns {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 45px;
  }

  .welcome .btns .btn {
    width: calc(50% - 5px);
    height: 41px;
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 410px) {
  .welcome .container .text {
    padding-left: 10px;
    padding-right: 10px;
  }

  .welcome .container .text .subtitle {
    padding-left: 0;
    padding-right: 0;
  }

  .welcome .container .mobileWelcomeBrush {
    max-width: 100%;
  }
}

@media (max-width: 370px) {
  .welcome .container .desktopWelcomeIcons {
    bottom: -80px;
  }

  .welcome .container .desktopWelcomeRestrict {
    width: 55px;
    height: 50px;
    bottom: -95px;
  }
}