.artist{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 401px;
}
.artist .image{
 position: relative;
 width: 398px;
 height: 398px;
 margin-bottom: 16px;

}
.artist .image:hover{
  cursor: pointer;
}
.artist .image img:first-child{
  width: 100%;
  height: 100%;

}
.artist .image img:last-child{
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
}
.artist .name{
  font-size: 39px;
  line-height: 47px;
  color: #4F2C1D;
  font-family: 'BloggerSans-Bold';
  word-spacing: 9999999px;
}
.artist .academy{
  font-size: 30px;
  line-height: 32px;
  color :#4F2C1D;
  font-family: 'GothamBook';
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 0;
  margin-top: auto;
}

@media (max-width: 1400px) {
  .artist{
    max-width: 350px;
  }
  .artist .image{
   width: 350px;
   height: 350px;
  }
  .artist .name{
    font-size: 35px;
    line-height: 41px;
  }
  .artist .academy{
    font-size: 28px;
    line-height: 29px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1250px) {
  .artist{
    max-width: 300px;
  }
  .artist .image{
   width: 300px;
   height: 300px;
  }
  .artist .name{
    font-size: 33px;
    line-height: 39px;
  }
  .artist .academy{
    font-size: 26px;
    line-height: 26px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1050px) {
  .artist{
    max-width: 250px;
  }
  .artist .image{
   width: 250px;
   height: 250px;
  }
  .artist .image img:last-child{
    width: 90px;
    height: 90px;
  }
  .artist .name{
    font-size: 29px;
    line-height: 33px;
  }
  .artist .academy{
    font-size: 23px;
    line-height: 22px;
  }
}

@media (max-width: 900px) {
  .artist{
    max-width: 230px;
  }
  .artist .image{
   width: 230px;
   height: 230px;
  }
  .artist .image img:last-child{
    width: 75px;
    height: 75px;
  }
  .artist .name{
    font-size: 24px;
    line-height: 27px;
  }
  .artist .academy{
    font-size: 20px;
    line-height: 21px;
  }
}

@media (max-width: 750px) {
  .artist{
    max-width: 175px;
  }
  .artist .image{
   width: 175px;
   height: 175px;
  }
  .artist .image img:last-child{
    width: 55px;
    height: 55px;
  }
  .artist .name{
    font-size: 20px;
    line-height: 21px;
  }
  .artist .academy{
    font-size: 15px;
    line-height: 16px;
  }
}

@media (max-width: 600px) {
  .artist{
    max-width: 155px;
  }
  .artist .image{
   width: 155px;
   height: 155px;
   margin-bottom: 10px;
  }
  .artist .name{
    font-size: 18px;
    line-height: 19px;
  }
}

@media (max-width: 530px) {
  .artist{
    max-width: 141px;
  }
  .artist .image{
   width: 141px;
   height: 141px;
   margin-bottom: 10px;
  }
  .artist .image img:last-child{
    width: 30px;
    height: 30px;
  }
  .artist .name{
    font-size: 16px;
    line-height: 17px;
  }
  .artist .academy{
    font-size: 10px;
    line-height: 11px;
  }
}

@media (max-width: 460px) {
  .artist{
    max-width: 117px;
  }
  .artist .image{
   width: 117px;
   height: 117px;
   margin-bottom: 10px;
  }
}

@media (max-width: 385px) {
  .artist{
    max-width: 106px;
  }
  .artist .image{
   width: 106px;
   height: 106px;
   margin-bottom: 10px;
  }
}

@media (max-width: 345px) {
  .artist{
    max-width: 98px;
  }
  .artist .image{
   width: 98px;
   height: 98px;
   margin-bottom: 10px;
  }
}
